<template>
	<ul class="menu-nav">
		<router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon menu-icon flaticon-shopping-basket"></i>
					<span class="menu-text">{{$t('MENU.MSG_ORDER')}}</span>
				</a>
			</li>
		</router-link>
		<router-link to="/category" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon menu-icon flaticon2-menu-4"></i>
					<span class="menu-text">{{$t('MENU.MSG_CATEGORY')}}</span>
				</a>
			</li>
		</router-link>
		<router-link to="/product" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon flaticon-web"></i>
					<span class="menu-text">{{$t('MENU.MSG_PRODUCT')}}</span>
				</a>
			</li>
		</router-link>
		<router-link to="/customer" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon flaticon2-settings"></i>
					<span class="menu-text">{{$t('MENU.MSG_SETTINGS')}} </span>
				</a>
			</li>
		</router-link>

		<router-link to="/builder" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon flaticon2-graphic-design"></i>
					<span class="menu-text">{{$t('MENU.MSG_DESIGN')}}</span>
				</a>
			</li>
		</router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
