<template>
  <div class="topbar-item">
		<span class="switch switch-sm">
			<label>
				<input ref="RefStatusShop" type="checkbox" @change="StatusShop();" :checked="getStatusShop"/>
				<span></span>
			</label>
		</span> 
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
	  
	 
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ currentUserPersonalInfo.name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUserPersonalInfo.name.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
		<!--begin::Header-->
		<div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
			<h3 class="font-weight-bold m-0">
				<!--<router-link to="/customer/profile" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">-->
				{{ getFullName }}
				<!--</router-link>-->
			</h3>
			<a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
				<i class="ki ki-close icon-xs text-muted"></i>
			</a>
		</div>
		<!--end::Header-->

      <!--begin::Content-->
		<perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative;">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
			<div class="symbol symbol-100 mr-5">
				<img class="symbol-label" :src="currentUserPersonalInfo.photo" alt=""/>
				<i class="symbol-badge bg-success" v-if="getStatusShop"></i>
				<i class="symbol-badge bg-danger" v-else></i>
			</div>
			<div class="d-flex flex-column">
            
				<div class="navi mt-2">
					<a :href="'https://doken.co/'+currentUserPersonalInfo.shop_url" target="_blank" class="navi-item">
						<span class="navi-link p-0 pb-2">
							<span class="navi-icon mr-1">
								<span class="svg-icon svg-icon-lg svg-icon-primary">
									<img src="media/klik/logo-min.png" style="width: 20px;"/>
								</span>
							</span>
							<span class="navi-text text-muted text-hover-primary">
								/{{ currentUserPersonalInfo.shop_url }}
							</span>
						</span>
					</a>
				</div>
				<!--<div class="navi mt-2">
					<a href="#" class="navi-item">
						<span class="navi-link p-0 pb-2">
							<span class="navi-icon mr-1">
								<span class="svg-icon svg-icon-lg svg-icon-primary">
									<inline-svg src="media/svg/icons/Communication/Call.svg"/>
								</span>
							</span>
							<span class="navi-text text-muted text-hover-primary">
								{{ currentUserPersonalInfo.settings.dial }}
							</span>
						</span>
					</a>
				</div>-->
				<button class="btn btn-light-primary btn-bold" @click="onLogout">
				  {{$t('MSG_LOGOUT')}}
				</button>
			</div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0 ">
		  <router-link to="/" @click.native="closeOffcanvas" href="#" class="navi-item" >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <router-link to="/">
                  <div class="font-weight-bold">{{currentUserPersonalInfo.unique_identity}}</div>
                </router-link>
                <div class="text-muted">
                 {{$t('MSG_UNIQUE_IDENTITY')}}
                </div>
              </div>
            </div>
			
          </router-link>
		 <!-- <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item" v-if="StatusPayActiveShop == 'E'"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <inline-svg
                      src="media/svg/icons/Files/Selected-file.svg"
                    />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <router-link to="/custom-pages/profile">
                  <div class="font-weight-bold">Versions d'essai</div>
                </router-link>
                <div class="text-muted">
                  {{DateDifference}} restants
                </div>
              </div>
            </div>
			
          </router-link>-->
			<!--<hr/>
			<div class="navi-link">
				<h3>Qr Code : </h3>
				<img :src="currentUserPersonalInfo.qr_code"/>
            </div>
          <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-warning">
                    <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Messages</div>
                <div class="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </router-link>
          <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <inline-svg src="media/svg/icons/Files/Selected-file.svg" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Activities</div>
                <div class="text-muted">Logs and notifications</div>
              </div>
            </div>
          </router-link>
          <router-link
            to="/builder"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg
                      src="media/svg/icons/Communication/Mail-opened.svg"
                    />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Tasks</div>
                <div class="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </router-link>-->
         
        </div>
        <!--
        <div class="separator separator-dashed my-7"></div>
        <div>
          <h5 class="mb-5">Recent Notifications</h5>
          <template v-for="(item, i) in list">
            <div
              class="d-flex align-items-center rounded p-5 gutter-b"
              v-bind:class="`bg-light-${item.type}`"
              v-bind:key="i"
            >
              <span
                class="svg-icon mr-5"
                v-bind:class="`svg-icon-${item.type}`"
              >
                <span class="svg-icon svg-icon-lg">
                  <inline-svg :src="item.svg" />
                </span>
              </span>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                  href="#"
                  class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {{ item.title }}
                </a>
                <span class="text-muted font-size-sm">
                  {{ item.desc }}
                </span>
              </div>
              <span
                class="font-weight-bolder py-1 font-size-lg"
                v-bind:class="`text-${item.type}`"
              >
                {{ item.alt }}
              </span>
            </div>
          </template>
        </div>
        -->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
//import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import axios from "axios";
export default {
  name: "KTQuickUser",
	data() {
		return {
			//SetStatusShop:false,
		};
	},
  mounted() {
	axios.defaults.headers["Content-Type"] =  "application/json";
	axios.defaults.withCredentials = true;
	axios.defaults.baseURL = process.env.VUE_APP_APP_API;
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
	if(this.StatusPayActiveShop != "A" && this.StatusPayActiveShop != "E"){
		this.$router.push({ name: "subscription" });
	}
	//this.SetStatusShop = this.currentUserPersonalInfo.name
  },
  methods: {
    onLogout() {
		localStorage.clear();
		this.$router.push({ name: "login" })
    },
	StatusShop() {
		if(this.StatusPayActiveShop != "A" && this.StatusPayActiveShop != "E"){
			this.$router.push({ name: "subscription" });
		}
		console.log(this.VerifPublicShop);
		var text = this.VerifPublicShop.join("<br><br><br>")
		if(this.VerifPublicShop.length != 0){
			Swal.fire({
					title: "",
					//text: this.VerifPublicShop,
					html: text,
					icon: "error",
					confirmButtonClass: "btn btn-secondary",
					heightAuto: false
				}); 
				this.$refs.RefStatusShop.checked = false;
			return;
		}
		this.currentUserPersonalInfo.open = !this.currentUserPersonalInfo.open;
		var status =  this.$refs.RefStatusShop.checked;
		axios.post("/klik/customer/open/close",{open:status}).then(() => {
			this.UpdateSession;
		}).catch(() => {
			this.UpdateSession;
		});
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["UpdateSession"]),
    ...mapGetters(["DateDifference"]),
    ...mapGetters(["StatusPayActiveShop"]),
    ...mapGetters(["VerifPublicShop"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name + " " + this.currentUserPersonalInfo.surname
      );
	 },
	getStatusShop() {
      return (
        this.currentUserPersonalInfo.open
      );
    }
  }
};
</script>
